import React from "react";
import classes from "./propertyCard.module.css";
import { FaImage } from "react-icons/fa";
import { Link } from "react-router-dom";

const baseURL = "http://adriaticum.hr";

const PropertyCard = ({ property }) => {
  return (
    <div key={property._id} className={classes.individualProperty}>
      <Link to={`/propertyDetail/${property._id}`} className={classes.imgContainer}>
        <img src={`${baseURL}/images/${property?.img[0]}`} alt="" />
        <div className={classes.imgCounter}>
          <FaImage className={classes.icon} />
          <h4>{property.img.length}</h4>
        </div>
      </Link>

      <div className={classes.details}>
        <Link to={`/propertyDetail/${property._id}`}>
          <div className={classes.title}>
            <h2>{property.title}</h2>
          </div>
        </Link>

        <div className={classes.type_subType}>
          <p>
            {property.type.toUpperCase()}, {property.subType}
          </p>
        </div>
        <div className={classes.sqmeters}>
          <h5>{property.sqmeters}m²</h5>
        </div>
        <div className={classes.price}>
          <h2>{property.price.toLocaleString("hr", { useGrouping: true })} €</h2>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
