import React, { useState } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import backgroundImage from "../../assets/split-pozadina.jpg";
import classes from "./hero.module.css";
import Properties from "../properties/Properties";

const Hero = () => {
  const [searchTitle, setSearchTitle] = useState("");
  const [results, setResults] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await axios.get(`/property/search?title=${searchTitle}`);
      window.scrollTo(0, 650);

      setResults(response.data);
      console.log(results);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.backgroundImageContainer}>
          <img src={backgroundImage} alt="" className={classes.backgroundImage} />
        </div>

         {/* <div className={classes.searchContainer}>
          <input
            type="text"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            placeholder="Naziv nekretnine..."
            className={classes.searchInput}
          />
          <button className={classes.searchButton} onClick={handleSearch}>
            <FaSearch />
          </button>

           <button className={classes.button}>Filtriraj +</button> 
        </div> */}

        <Properties searchFilteredProperties={results} /> 
      </div>
    </div>
  );
};

export default Hero;
