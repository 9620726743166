import React, { useState, useEffect, useCallback } from "react";
import classes from "./filteredPropertyList.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import PropertyCard from "../propertyCard/PropertyCard";

const FilteredPropertyList = () => {
  const { type, subType } = useParams();
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [displayCount, setDisplayCount] = useState(9);
  const titleType = type.charAt(0).toUpperCase() + type.slice(1);

  const fetchFilteredProperties = useCallback(async () => {
    try {
      let apiUrl = `/property/find?type=${type}`;
      if (subType) {
        const encodedSubType = encodeURIComponent(subType);
        apiUrl += `&subType=${encodedSubType}`;
      }
      const response = await axios.get(apiUrl);
      setFilteredProperties(response.data);
    } catch (error) {
      console.error("Error fetching filtered properties:", error);
    }
  }, [type, subType]);

  useEffect(() => {
    fetchFilteredProperties();
  }, [fetchFilteredProperties]);

  const loadMore = () => {
    setDisplayCount((prevCount) => prevCount + 9);
  };

  return (
    <div className={classes.container}>
      {filteredProperties && filteredProperties.length > 0 ? <h1>{titleType}  nekretnina</h1> : <h1>Nema rezultata</h1>}
      <div className={classes.filteredProperties}>
        {filteredProperties.slice(0, displayCount).map((property) => (
          <PropertyCard key={property._id} property={property} />
        ))}
      </div>
      <div className={classes.loadMore}>
        {displayCount < filteredProperties.length && <button className={classes.button} onClick={loadMore}>Učitaj više</button>}
      </div>
    </div>
  );
};

export default FilteredPropertyList;
