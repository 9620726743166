import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './components/nav/Nav';
import Hero from './components/hero/Hero';
import FilteredPropertyList from './components/filteredPropertyList/FilteredPropertyList';
import PropertyDetail from './components/propertyDetail/PropertyDetail';
import Footer from './components/footer/Footer';
import FileUpload from './components/fileUpload/FileUpload';
import Info from './components/info/Info';
import Contact from './components/contact/Contact';
import OpciUvjetiPolitikaPrivatnosti from './components/opciUvjetiPolitikaPrivatnosti/opciUvjetiPolitikaPrivatnosti';

function App() {

  return (
    <Router>
      <div>
        <Nav />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
            </>
          } />
          <Route path="/propertyDetail/:id" element={<PropertyDetail />} />
          <Route path="/properties/:type/:subType?" element={<FilteredPropertyList />} />
          <Route path="/upload" element={<FileUpload />} />
          <Route path="/adriaticum-nekretnine-info" element={<Info/>} />
          <Route path="/kontakt" element={<Contact/>}/>
          <Route path="/opci-uvjeti-i-politika-privatnosti" element={<OpciUvjetiPolitikaPrivatnosti/>}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

