import React, { useState, useEffect } from "react";
import axios from "axios";
import classes from "./properties.module.css";
import PropertyCard from "../propertyCard/PropertyCard";

const Properties = ({ searchFilteredProperties }) => {
  
  const [allProperties, setAllProperties] = useState([]);

  useEffect(() => {
    const fetchAllProperties = async () => {
      const response = await axios.get("https://adriaticum.hr:8080/property/getAll");
      setAllProperties(response.data);
    };

    if (searchFilteredProperties && searchFilteredProperties.length > 0) {
      setAllProperties(searchFilteredProperties); 
    } else {
      fetchAllProperties(); 
    }
  }, [searchFilteredProperties]);

  let propertyCards = null;

  if (Array.isArray(allProperties)) {
    const slicedProperties = allProperties.slice(0, 9);
    propertyCards = slicedProperties.map((property) => (
      <PropertyCard key={property._id} property={property} />
    ));
  } else {
    console.error("allProperties is not an array.");
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.naslov}>
          <h1>Nekretnine</h1>
        </div>
        <div className={classes.properties}>{propertyCards}</div>
        
      </div>
    </div>
  );
};

export default Properties;
