import React, { useState, useEffect } from "react";
import classes from "./nav.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import adriaticumLogo from "../../assets/adriaticumNoviLogo.png";
import njuskaloIcon from "../../assets/njuskaloIkona.png";
import { AiFillCaretDown } from "react-icons/ai";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Nav = () => {
  const [toggled, setToggled] = React.useState(false);
  const prodajaURL = "/properties/prodaja";
  const najamURL = "/properties/najam";
  const [propertyCountProdaja, setPropertyCountProdaja] = useState({
    stan: 0,
    apartman: 0,
    kuca: 0,
    poslovni_prostor: 0,
    zemljiste: 0,
  });
  const [propertyCountNajam, setPropertyCountNajam] = useState({
    stan: 0,
    apartman: 0,
    kuca: 0,
    poslovni_prostor: 0,
    zemljiste: 0,
  });

  let prodajaCount =
    propertyCountProdaja.stan +
    propertyCountProdaja.apartman +
    propertyCountProdaja.kuca +
    propertyCountProdaja["poslovni prostor"] +
    propertyCountProdaja.zemljiste;

  let najamCount =
    propertyCountNajam.stan +
    propertyCountNajam.apartman +
    propertyCountNajam.kuca +
    propertyCountNajam["poslovni prostor"] +
    propertyCountNajam.zemljiste;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleMenuToggle = () => {
    setToggled(!toggled);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get("https://adriaticum.hr:8080/property/find/subTypes");
        setPropertyCountProdaja(response.data.prodaja);
        setPropertyCountNajam(response.data.najam);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <Link to="/" onClick={scrollToTop} className={classes.left}>
          <img src={adriaticumLogo} alt="Adriaticum logo" className={classes.logo} />
        </Link>
        
        <div className={classes.middle}>
          <Link to="https://www.njuskalo.hr/agencija/adriaticum-qualitas">
            <img src={njuskaloIcon} alt="Njuškalo ikona" title="Link za Njuškalo"/>
          </Link>
        </div>

        
        <div className={classes.right}>
          <Link to="/">
            <h3>Početna</h3>
          </Link>

          <div className={classes.dropdownContainer}>
            <h3>
              Nekretnine
              <AiFillCaretDown className={classes.caretIcon} />
            </h3>

            <div className={classes.dropdownNekretnine}>
              <div className={classes.propertyType}>
                <Link to={prodajaURL} onClick={scrollToTop}>
                  <h3>Prodaja ({prodajaCount})</h3>
                </Link>
                <div className={classes.subTypeDropdown}>
                  <Link to={`${prodajaURL}/stan`} onClick={scrollToTop}>
                    <p>Stan ({propertyCountProdaja.stan})</p>
                  </Link>
                  <Link to={`${prodajaURL}/apartman`} onClick={scrollToTop}>
                    <p>Apartman ({propertyCountProdaja.apartman})</p>
                  </Link>
                  <Link to={`${prodajaURL}/kuca`} onClick={scrollToTop}>
                    <p>Kuća ({propertyCountProdaja.kuca})</p>
                  </Link>
                  <Link to={`${prodajaURL}/poslovni prostor`} onClick={scrollToTop}>
                    <p>Poslovni prostor ({propertyCountProdaja["poslovni prostor"]})</p>
                  </Link>
                  <Link to={`${prodajaURL}/zemljiste`} onClick={scrollToTop}>
                    <p>Zemljište ({propertyCountProdaja.zemljiste})</p>
                  </Link>
                </div>
              </div>
              <div className={classes.propertyType}>
                <Link to={najamURL} onClick={scrollToTop}>
                  <h3>Najam ({najamCount})</h3>
                </Link>
                <div className={classes.subTypeDropdown}>
                  <Link to={`${najamURL}/stan`} onClick={scrollToTop}>
                    <p>Stan ({propertyCountNajam.stan})</p>
                  </Link>
                  <Link to={`${najamURL}/apartman`} onClick={scrollToTop}>
                    <p>Apartman ({propertyCountNajam.apartman})</p>
                  </Link>
                  <Link to={`${najamURL}/kuca`} onClick={scrollToTop}>
                    <p>Kuća ({propertyCountNajam.kuca})</p>
                  </Link>
                  <Link to={`${najamURL}/poslovni prostor`} onClick={scrollToTop}>
                    <p>Poslovni prostor ({propertyCountNajam["poslovni prostor"]})</p>
                  </Link>
                  <Link to={`${najamURL}/zemljiste`} onClick={scrollToTop}>
                    <p>Zemljište ({propertyCountNajam.zemljiste})</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.dropdown}>
            <Link to="/adriaticum-nekretnine-info" onClick={scrollToTop}>
              <h3>Info</h3>
            </Link>
            <div className={classes.dropdownInfo}>
              <Link to="/adriaticum-nekretnine-info" onClick={scrollToTop}>
                <p>Adriaticum nekretnine info</p>
              </Link>
              <Link to="/kontakt" onClick={scrollToTop}>
                <p>Kontakt</p>
              </Link>
              <Link to="/opci-uvjeti-i-politika-privatnosti" onClick={scrollToTop}>
                <p>Opći uvjeti i politika privatnosti</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.sidebarContainer}>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: 10 }}>
          <MenuOutlinedIcon onClick={() => setToggled(!toggled)} />
        </div>
        <Sidebar
          className={classes.rightSidebar}
          backgroundColor="lightgray"
          width="300px"
          onBackdropClick={() => setToggled(false)}
          toggled={toggled}
          breakPoint="all"
        >
          <Menu>
            <MenuItem icon={<MenuOutlinedIcon />} onClick={() => setToggled(false)}></MenuItem>
            <MenuItem component={<Link to="/" onClick={handleMenuToggle} />}> Početna </MenuItem>
            <SubMenu label="Nekretnine">
              <MenuItem component={<Link to={prodajaURL} onClick={handleMenuToggle} />}>
                <h3>Prodaja ({prodajaCount})</h3>
              </MenuItem>
              <MenuItem component={<Link to={`${prodajaURL}/stan`} onClick={handleMenuToggle} />}>
                {" "}
                Stan ({propertyCountProdaja.stan})
              </MenuItem>
              <MenuItem component={<Link to={`${prodajaURL}/apartman`} onClick={handleMenuToggle} />}>
                Apartman ({propertyCountProdaja.apartman})
              </MenuItem>
              <MenuItem component={<Link to={`${prodajaURL}/kuca`} onClick={handleMenuToggle} />}>
                {" "}
                Kuća ({propertyCountProdaja.kuca})
              </MenuItem>
              <MenuItem component={<Link to={`${prodajaURL}/poslovni prostor`} onClick={handleMenuToggle} />}>
                Poslovni prostor ({propertyCountProdaja["poslovni prostor"]})
              </MenuItem>
              <MenuItem component={<Link to={`${prodajaURL}/zemljiste`} onClick={handleMenuToggle} />}>
                Zemljište ({propertyCountProdaja.zemljiste})
              </MenuItem>
              <MenuItem component={<Link to={najamURL} onClick={handleMenuToggle} />}>
                {" "}
                <h3>Najam ({najamCount})</h3>
              </MenuItem>
              <MenuItem component={<Link to={`${najamURL}/stan`} onClick={handleMenuToggle} />}>
                Stan ({propertyCountNajam.stan})
              </MenuItem>
              <MenuItem component={<Link to={`${najamURL}/apartman`} onClick={handleMenuToggle} />}>
                Apartman ({propertyCountNajam.apartman})
              </MenuItem>
              <MenuItem component={<Link to={`${najamURL}/kuca`} onClick={handleMenuToggle} />}>
                Kuća ({propertyCountNajam.kuca})
              </MenuItem>
              <MenuItem component={<Link to={`${najamURL}/poslovni prostor`} onClick={handleMenuToggle} />}>
                Poslovni prostor ({propertyCountNajam["poslovni prostor"]})
              </MenuItem>
              <MenuItem component={<Link to={`${najamURL}/zemljiste`} onClick={handleMenuToggle} />}>
                Zemljište ({propertyCountNajam.zemljiste})
              </MenuItem>
            </SubMenu>
            <MenuItem component={<Link to="/adriaticum-nekretnine-info" onClick={handleMenuToggle} />}> Info </MenuItem>
            <MenuItem component={<Link to="/kontakt" onClick={handleMenuToggle} />}> Kontakt </MenuItem>
            <MenuItem component={<Link to="/opci-uvjeti-i-politika-privatnosti" onClick={handleMenuToggle} />}> Opći uvjeti i politika privatnosti </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </div>
  );
};

export default Nav;
