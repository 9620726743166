import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "./propertyDetail.module.css";
import axios from "axios";
import { FaImage } from "react-icons/fa";
import{ RiLayout4Line } from "react-icons/ri";

const baseURL = "http://localhost:5000";

const PropertyDetail = () => {
  const [propertyDetail, setPropertyDetail] = useState(null);
  const { id } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const data = await axios.get(`/property/find/${id}`);
        setPropertyDetail(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDetails();
  }, [id]);

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + propertyDetail?.data?.img.length) % propertyDetail?.data?.img.length
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % propertyDetail?.data?.img.length);
  };

  const imeVanjskogProstora = () => {
    
    if(propertyDetail?.data?.outerSpace === "loda") {
      return "lođa";
    }else if(propertyDetail?.data?.outerSpace === "balkon i loda") {
      return "balkon i lođa";
    }
  }

  if (!propertyDetail) {
    return <p>Loading...</p>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.left}>
          <img
            src={`${baseURL}/images/${propertyDetail?.data?.img[currentImageIndex]}`}
            alt="nekretnina"
            className={classes.image}
          />

          <button type="button" className={classes.leftArrow} onClick={handlePreviousImage}>
            &lt;
          </button>
          <button type="button" className={classes.rightArrow} onClick={handleNextImage}>
            &gt;
          </button>

          <div className={classes.imgIdx}>
            <FaImage className={classes.icon} />
            <h4>
              {currentImageIndex + 1} / {propertyDetail?.data?.img.length}
            </h4>
          </div>
        </div>

        <div className={classes.right}>
          <h1 className={classes.title}>
            {propertyDetail?.data?.title}, {propertyDetail?.data?.type}
          </h1>
          <div className={classes.details}>
            <p>
              {propertyDetail?.data?.type.toUpperCase()} {propertyDetail?.data?.subType}
            </p>
            <p className={classes.sqmeters}><i><RiLayout4Line /></i>{propertyDetail?.data?.sqmeters}m²</p>
            <p className={classes.numOfRooms}>Broj soba: {propertyDetail?.data?.numOfRooms}</p>
            <p className={classes.numOfBathrooms}>Broj kupaonica: {propertyDetail?.data?.numOfBathrooms}</p>
            <p className={classes.orientation}>Orijentacija: {propertyDetail?.data?.orientation}</p>
            <p className={classes.floor}>Kat: {propertyDetail?.data?.floor}</p>
            <p className={classes.outerSpace}>Tip vanjskog prostora: {imeVanjskogProstora()}</p>
            <h1 className={classes.price}>
              {`${propertyDetail?.data?.price.toLocaleString("hr", {
                useGrouping: true,
              })}`}{" "}
              €{" "}
            </h1>
          </div>
        </div>

        <div className={classes.bottom}>
          <div className={classes.desc}>
            <h2>Opis nekretnine</h2>
            <p>{`${propertyDetail?.data?.desc}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetail;
