import React from 'react'
import classes from './opciUvjetiPolitikaPrivatnosti.module.css'

const opciUvjetiPolitikaPrivatnosti = () => {
  return (
    <div className={classes.container}>
        <div className={classes.uvjetiPoslovanja}>
    <h3 className={classes.naslov}>UVJETI POSLOVANJA</h3>
    <p>1. OPĆE ODREDBE</p>
    <p>
        Općim uvjetima poslovanja uređuje se poslovni odnos između agencije za posredovanje u prometu
        nekretnina Adriaticum Qualitas d.o.o. (dalje u tekstu: Posrednik) i nalogodavca (fizičke ili
        pravne osobe). Sklapanjem Ugovora o posredovanju nalogodavac potvrđuje da je upoznat i suglasan
        s odredbama ovih uvjeta poslovanja.
    </p>

    <p>
        Opći uvjeti su sastavni dio ugovora o posredovanju sklopljenog između posrednika i nalogodavca.{" "}
    </p>

    <p>
        Opći uvjeti su sastavni dio ugovora o posredovanju sklopljenog između posrednika i nalogodavca.{" "}
    </p>

    <p>
        Posrednik u prometu nekretnina – agencija za posredovanje u prometu nekretnina "Adriaticum
        Qualitas d.o.o."
    </p>

    <p>
        Nalogodavac je fizička i/ili pravna osoba koja s Posrednikom sklopi Ugovor o posredovanju
        (prodavatelj, kupac, zakupnik, zakupodavac, najmodavac, najmoprimac i dr...)
    </p>

    <p>
        Posredovanje u prometu nekretnina su sve radnje Posrednika u prometu nekretninama koje se odnose
        na povezivanje nalogodavca i treće osobe te pregovora i priprema za sklapanje pravnih poslova
        kojih je predmet određena nekretnina osobito pri kupnji, prodaji, zamjeni, najmu, zakupu i dr.
    </p>

    <p>
        Treća osoba je osoba koju Posrednik u prometu nekretninama nastoji povezati s Nalogodavcem radi
        pregovora o sklapanju pravnih poslova predmet kojih je određena nekretnina.
    </p>

    <p>
        Posrednička naknada je iznos koji je Nalogodavac dužan isplatiti Posredniku za usluge
        posredovanja
    </p>

    <p>2. PONUDA NEKRETNINA</p>
    <p>
        Ponuda nekretnina temelji se na podacima koje je agencija zaprimila pismenim, usmenim ili
        elektroničkim putem, te je uvjetovana potpisom. Agencija zadržava mogućnost greške u opisu i
        cijeni nekretnine, mogućnost da je oglašena nekretnina već prodana (ili iznajmljena) ili je
        vlasnik odustao od prodaje (ili najma).
    </p>

    <p>
        Ponude i obavijesti agencije primatelj (nalogodavac) mora čuvati kao poslovnu tajnu i samo ih uz
        pismeno odobrenje agencije smije prenijeti trećim osobama.
    </p>

    <p>
        Ako je primatelj ponude već upoznat s nekretninama koje mu je agencija ponudila, obavezan je o
        tome bez odgode obavijestiti agenciju.
    </p>

    <p>
        Ponuda i/ili podaci o nekretninama se smatraju potvrđeni potpisivanjem Ugovora o posredovanju
        između Nalogodavca i Posrednika.
    </p>

    <p>
        Agencija nije odgovorna za greške iz prethodnog stavka ovog članka osim u slučaju namjernih
        grešaka i/ili izrazito nemarnog ponašanja agencije.
    </p>

    <p>Agencija nije odgovorna za greške i/ili izrazito nemarno ponašanje Nalogodavca.</p>

    <p>3. CIJENA NEKRETNINA</p>
    <p>Cijene nekretnina iskazane su u EUR</p>

    <p>4. UGOVOR O POSREDOVANJU</p>
    <p>
        Ugovorom o posredovanju Posrednik se obvezuje dovesti u vezu s Nalogodavcem treću osobu koja bi
        sa njim pregovarala o sklapanju pravnog posla, a Nalogodavac se obvezuje isplatiti posredniku
        posredničku naknadu, ako se sklopi ugovor o pravnom poslu.
    </p>

    <p>Ugovor o posredovanju sklapa se između Posrednika i Nalogodavca.</p>

    <p>
        U ugovoru o posredovanju moraju biti točno, istinito i potpuno naznačeni svi podaci o nekretnini
        za potrebe posredovanog posla, te obveze Posrednika i Nalogodavca.
    </p>

    <p>
        Ako je nekretnina u suvlasništvu više osoba, potreban je pisani pristanak (punomoć) svih
        suvlasnika ili predstavnika svih suvlasnika nekretnine u vidu prihvaćanja Ugovora o
        posredovanju.
    </p>

    <p>
        5. OBVEZE AGENCIJE POSREDNIKA PRILIKOM POSREDOVANJA PRI KUPNJI, PRODAJI, ZAKUPU I NAJMU
        NEKRETNINA
    </p>
    <p>
        zaključiti Ugovor o posredovanju s nalogodavcem u pisanom obliku nastojati naći i dovesti u vezu
        s nalogodavcem osobu radi sklapanja posredovanog posla; upoznati nalogodavca s prosječnom
        tržišnom cijenom slične nekretnine; upozoriti nalogodavca na nedostatke nekretnine; izvršiti
        uvid u isprave kojima se dokazuje vlasništvo ili drugo stvarno pravo na predmetnoj nekretnini i
        upozoriti nalogodavca na: očite nedostatke i moguće rizike u svezi s neuređenim
        zemljišnoknjižnim stanjem nekretnine; upisana stvarna prava ili druga prava trećih na
        nekretninu; pravne posljedice neispunjavanja obaveza prema trećoj strani; nedostatke građevne
        ili uporabne dozvole sukladno posebnom zakonu; okolnosti obveze primjene prava prvokupa i
        ograničenja u pravnom prometu sukladno posebnim propisima; obaviti potrebne radnje radi
        predstavljanja (prezentacije) nekretnine na tržištu, oglasiti nekretninu na način koji odredi
        agencija; omogućiti pregled nekretnina, čuvati osobne podatke nalogodavca, te po pisanom nalogu
        nalogodavca čuvati kao poslovnu tajnu podatke o nekretnini za koju posreduje ili u vezi s tom
        nekretninom ili s poslom za koji posreduje; obavijestiti nalogodavca o svim okolnostima
        značajnim za namjeravani posao koje su nam poznate; posredovati u pregovorima i nastojati da
        dođe do zaključenja pravnog posla; prisustvovati kod zaključenja pravnog posla (Predugovora i
        Ugovora); prisustvovati primopredaji nekretnine; ako je predmet sklapanja ugovora zemljište,
        provjeriti namjenu predmetnog zemljišta u skladu s propisima o prostornome uređenju koji se
        odnose na to zemljište; - Smatra se da je posrednik omogućio nalogodavcu vezu s drugom osobom
        (fizičkom ili pravnom) o pregovaranju za sklapanje posredovanog posla, ako je omogućeno
        nalogodavcu supanje u vezu s drugom osobom s kojom je pregovarao za sklapanje pravnog posla, a
        naročito ako je: - neposredno odveo ili uputio nalogodavca ili treću osobu u razgledavanje
        predmetne nekretnine, - organizirao susret između nalogodavca i druge ugovorne osobe radi
        pregovaranja za sklapanje pravnog posla; - nalogodavcu priopćio ime, broj telefona, e-maila
        druge osobe ovlaštene za sklapanje pravnog posla ili mu je priopćio točnu lokaciju tražene
        nekretnine.
    </p>

    <p>6. OBVEZE NALOGODAVCA</p>
    <p>
        Zaključiti Ugovor o posredovanju s agencijom, u pisanom obliku; Obavijestiti posrednika o svim
        okolnostima koje su važne za obavljanje posredovanja i predočiti točne podatke o nekretnini te
        ako posjeduje dati posredniku na uvid lokacijsku, građevnu, odnosno uporabnu dozvolu za
        nekretninu koja je predmet ugovora te dati na uvid posredniku dokaze o ispunjavanju obveza prema
        trećoj strani, Dati posredniku na uvid isprave koje dokazuju njegovo vlasništvo na nekretnini,
        odnosno drugo stvarno pravo na nekretnini koja je predmet ugovora te upozoriti posrednika na sve
        uknjižene i neuknjižene terete koji postoje na nekretnini, Osigurati posredniku i trećoj osobi
        zainteresiranoj za zaključivanje posredovanoga posla razgledanje nekretnine, Obavijestiti
        posrednika o svim bitnim podacima o traženoj nekretnini što posebno uključuje opis nekretnine i
        cijenu, Nakon sklapanja posredovanoga pravnog posla, odnosno predugovora kojim se obvezao
        sklopiti posredovani pravni posao, ako su posrednik i nalogodavac ugovorili da se pravo na
        plaćanje posredničke naknade stječe već pri sklapanju predugovora, isplatiti posredniku
        posredničku naknadu, osim ako nije drukčije ugovoreno, Ako je to izričito ugovoreno naknaditi
        posredniku troškove učinjene tijekom posredovanja koji prelaze uobičajene troškove posredovanja,
        Obavijestiti posrednika pisanim putem o svim promjenama povezanim s poslom za koji je ovlastio
        posrednika, a posebno o promjenama povezanim s vlasništvom na nekretnini. Nalogodavac će
        odgovarati za štetu, ako pri tom nije postupio u dobroj vjeri, ako je postupio prijevarno, ako
        je zatajio ili dao netočne podatke bitne za posao posredovanja u cilju okončanja pravnog posla,
        te je dužan naknaditi sve troškove učinjene tijekom posredovanja, koji ne mogu biti veći od
        posredničke naknade za posredovani posao.
    </p>

    <p> 7. POSREDNIČKA NAKNADA </p>
    <p>
        {" "}
        Posredniku za posredovanje pripada posrednička naknada dogovorena Ugovorom o posredovanju (u
        daljnjem tekstu : naknada)
    </p>

    <p>
        Posrednik je za svoj rad obvezan naplatiti naknadu u iznosu određenom Ugovorom o posredovanju.
        Posrednik stječe pravo na posredničku naknadu u cijelosti, neposredno nakon sklapanja prvog
        pravnog akta kojeg ugovorne strane zaključe (Predugovor ili kupoprodajni ugovor).
    </p>

    <p>
        Posrednička naknada koju plaća nalogodavac za uslugu posredovanja kod kupnje ili prodaje (li
        zakupa, najma i slično..) nekretnine ugovara se za svaki posao kupoprodaje ili drugog pravnog
        posla kojim nekretnina mijenja vlasnika i slično, zasebno, prema važećim zakonima i propisima.
    </p>

    <p>
        Ukoliko stanka sam ponudi posredniku naknadu veću od ugovorene, posrednik smije primiti takvu
        naknadu.
    </p>

    <p>Visina posredničke naknade slobodno se određuje ugovorom o posredovanju u prometu nekretnina.</p>

    <p>
        Posrednik može ugovoriti pravo na naknadu troškova nužnih za izvršenje naloga i zatražiti da joj
        se unaprijed akontiraju sredstva za određene izdatke tj. troškove.
    </p>

    <p>
        U slučaju posredovanja, u kojem je stranka nalogodavac sama ili preko treće osobe našla
        zainteresiranu osobu, posrednik može u skladu sa svojom savješću naplatiti stvarne troškove
        nastale u svezi predmetnog posredovanja.
    </p>

    <p>
        Smatra se da je posrednik omogućio nalogodavcu vezu s drugom osobom o pregovaranju za sklapanje
        posredovanog posla, ako je omogućeno nalogodavcu stupanje u vezu s drugom osobom (fizičkom ili
        pravnom) s kojom je pregovarao za sklapanje pravnog posla a naročito ako je:
    </p>

    <p>
        neposredno odveo ili uputio nalogodavca ili treću osobu u razgledavanje predmetne nekretnine;
        organizirao susret između nalogodavca i druge ugovorne osobe radi pregovaranja za sklapanje
        pravnog posla; nalogodavcu priopćio ime, broj telefona, telefaksa druge osobe ovlaštene za
        sklapanje pravnog posla ili mu je priopćio točnu lokaciju tražene nekretnine.
    </p>

    <p>8. PRESTANAK UGOVORA</p>
    <p>
        Ugovor o posredovanju sklapa se na određeni rok i prestaje istekom roka na koji je sklopljen,
        osim ako u tom roku nije sklopljen ugovor za koji je posredovano ili otkazom bilo koje od
        ugovornih strana. Ugovor se može sporazumno produžiti.
    </p>
    <p>
        Nalogodavac će priznati posredovanje i nakon isteka ugovora ukoliko je kontakt s trećom osobom
        ostvaren tijekom trajanja ugovora.
    </p>
    <p>
        Ukoliko nalogodavac prije isteka ugovorenog roka jednostrano raskine ugovor, dužan je u roku od
        7 (sedam) dana posredniku nadoknaditi sve troškove posredovanja koji su nastali do raskida
        ugovora, a koji se odnose na troškove oglašavanja, materijalne troškove i drugo, a sve ukoliko
        su isti ugovoreni i ukoliko su nastali.
    </p>
    <p>
        Kad ugovor prestane zbog isteka vremena, ugovorne strane nemaju jedna prema drugoj nikakve
        tražbine. Nalogodavac je dužan naknaditi posredniku učinjene troškove za koje je inače bilo
        ugovoreno da ih nalogodavac posebno plaća.
    </p>
    <p>
        Ako u roku od 2 godine nakon prestanka važenja ugovora nalogodavac sklopi pravni posao, s osobom
        s kojom ga je povezao posrednik, a za koji je posrednik posredovao u vrijeme važenja ugovora,
        dužan je posredniku platiti posredničku naknadu, osim ako Ugovorom nije drugačije ugovoreno.
    </p>

    <p> 9. SURADNJA S DRUGIM AGENCIJAMA </p>
    <p>
        Agencija je spremna za suradnju s drugim agencijama za posredovanje koje poštuju temeljna etička
        načela (kojima se isključuje iznošenje neistinitih podataka o poslovanju radi pribavljanja
        poslova i stranaka, omalovažavanje drugih agencija na bilo koji način, radi pribavljanja poslova
        i stranaka, nerealne procjene nekretnina radi pribavljanja posredničkih poslova i isključivanja
        drugih agencija s tržišta, istupanje u sredstvima informiranja s namjerom osobne promidžbe, a na
        štetu drugih agencija).
    </p>

    <p>Međusobna suradnja agencija temelji se na Kodeksu etike poslovanja posrednika.</p>

    <p>10. OPĆE ODREDBE I RJEŠAVANJE SPOROVA</p>
    <p>
        Na odnose između nalogodavca i agencije proizašle iz ugovora o posredovanju koji nisu utvređeni
        ovim Općim uvjetima poslovanja niti ugovorom o posredovanju, primjenjuju se odredbe Zakona o
        posredovanju u prometu nekretnina i Zakona o obveznim odnosima.
    </p>
    <p>Za moguće sporove nadležan je općinski sud u Splitu.</p>
</div>

<div className={classes.politikaPrivatnosti}>
    <h3 className={classes.naslov}>POLITIKA PRIVATNOSTI</h3>
    <p>
        Kako prikupljamo osobne podatke Adriaticum Qualitas d.o.o. prikuplja Vaše osobne podatke, između
        ostalog, u slijedećim slučajevima:
    </p>
    <p>-ako nas izravno kontaktirate putem bilo koje internetske stranice</p>
    <p>-kako biste zatražili informacije ili ponudu za naše usluge</p>
    <p>-ako izravno kod nas kupite uslugu</p>
    <p>
        -ako ste mlađi od 16 godina, molimo vas da nam ne pružate nikakve podatke bez odobrenja
        roditelja ili skrbnika.
    </p>

    <p>Kako se vaši podaci mogu koristiti</p>
    <p>
        Korištenje osobnih podataka u skladu s propisima o zaštiti osobnih podataka mora biti opravdano
        temeljem jedne od pravnih “osnova”, a mi ovim pravilnikom utvrđujemo osnove za svaku upotrebu
        osobnih podataka.
    </p>
    <p>
        Opravdane osnove za obradu podataka su, između ostalog, legitimni interes, ugovorna obveza,
        zakonska osnova i suglasnost. Obradu na temelju legitimnog interesa Adriaticum Qualitas d.o.o.,
        koristi za promoviranjem i pružanjem informacija o svojim uslugama, u svrhu održavanja najviših
        standarda prodaje usluga iz svoje ponude. Temeljna prava i slobode postojećih i potencijalnih
        kupaca odmjerena su u odnosu na interes Adriaticum Qualitas d.o.o., da obrađuje podatke u
        navedenu svrhu.
    </p>
    <p>
        Osobni podaci mogu se prenositi trećim stranama pod uvjetom da postoji opravdana osnova za
        prijenos, kao što je ispunjenje usluge.
    </p>
    <p>
        Adriaticum Qualitas d.o.o. podliježe zakonima Republike Hrvatske, kao i nadnacionalnim
        propisima, i dužna ih se pridržavati, uključujući pružanje vaših podataka tijelima za provedbu
        zakona, regulatornim i sudskim tijelima te trećim parničnim stranama vezano uz postupke ili
        istrage bilo gdje u svijetu gdje je to zahtijevano. Tamo gdje je to dopušteno, izravno ćemo vam
        uputiti takav zahtjev ili vas obavijestiti prije odgovora, osim ako bi to moglo utjecati na
        sprječavanje ili otkrivanje zločina.
    </p>
    <p>
        Pružanje osobnih podataka u svrhu sukladnosti s obvezujućim zahtjevima za vašim podacima
        predstavlja zakonsku obvezu koja ovisi o konkretnom zahtjevu.
    </p>
    <p>
        Obvezujemo se čuvati privatnost vaših osobnih podataka te s istima postupati u skladu s Općom
        uredbom o zaštiti podataka (679/2016) EU, Zakonom o provedbi Opće uredbe o zaštiti podataka (NN
        42/2018) i drugim važećim primjenjivim propisima. Prikupljene osobne podatke korisnika kao i
        ostalih kupaca, ne smijemo i nećemo neovlašteno upotrebljavati niti učiniti dostupnim trećim
        osobama, osim u slučajevima kada to poseban zakon dozvoljava, ukoliko to čini našu zakonsku
        obvezu ili je pak potrebno u svrhu realizacije ugovornih obveza.
    </p>
    <p>
        Obvezujemo se da nećemo zloupotrebljavati osobne podatke iz registracijskog ili drugog obrasca ,
        niti ih bez vašeg dopuštenja prepuštati trećim osobama, osim u slučajevima u kojima tako
        izričito propisuje zakon te u slučajevima kada je to potrebno za ispunjenje obveza. Osobnim
        podacima smatraju se svi podaci kojima se utvrđuje identitet Korisnika (primjerice ime i
        prezime, adresa epošte, adresa stanovanja i sl.), a koji služe za odgovaranje na upite
        Korisnika, statistiku te eventualnog slanja posebnih ponuda i newslettera, a po posebno
        pribavljenoj privoli.
    </p>
    <p>
        Svi podaci o korisnicima strogo se čuvaju i dostupni su samo djelatnicima kojima su ti podaci
        nužni za obavljanje posla. Svi djelatnici Adriaticum Qualitas d.o.o., kao i poslovni partneri
        odgovorni su za poštivanje načela zaštite privatnosti. Obavezujemo se pružati zaštitu vaših
        osobnih podataka na način da prikupljamo samo osnovne podatke koji su nužni za ispunjenje svrhe
        dane privole ili legitimnog interesa, ugovorne ili zakonske osnove.
    </p>
    <p>
        Prema važećem nacionalnom i nadnacionalnom zakonodavstvu, u cilju zaštite povjerljivosti osobnih
        podataka, posebno se obvezujemo postupati s vašim podacima u skladu sa zakonom i dobroj vjeri,
        prikupljati podatke isključivo u točno određene i zakonite svrhe, ne prosljeđivati podatke bilo
        kojoj trećoj osobi bez vaše prethodne privole.
    </p>
    <p>
        Za slučaj da više ne želite da više na bilo koji način obrađujemo vaše podatke, zahtijevate
        brisanje, ispravak ili prijenos vaših podataka, o tome nas obavijestite, i to putem e-mail
        poruke na e-mail adriaticum.nekretnine@gmail.com ili na broj telefona +385 91 37777 60.
    </p>
    <p>Adriaticum Qualitas d.o.o. može kontaktirati Korisnika radi provjere autentičnosti zahtjeva.</p>
    <p>
        Korisnik koji je fizička osoba može u bilo kojem trenutku zatražiti da mu/joj Adriaticum
        Qualitas d.o.o.:
    </p>

    <p>
        Potvrdi obrađuju li se podaci u odnosu na Korisnika te omogućiti pregled osobnih podataka
        sadržanih u sustavu pohrane osobnih podataka i da ih kopira; <br />
        Proslijedi osobne podatke koji su sadržani u sustavu pohrane podataka; <br />
        Pruži popis trećih osoba kojima su preneseni osobni podaci, kada su im preneseni, na temelju
        čega i u koju svrhu; <br />
        Pruži informacije o izvorima na kojima se temelje evidencije koje osobne podatke sustav pohrane
        sadrži o pojedincu i načinu obrade; <br />
        Pruži informacije o svrsi obrade i vrsti osobnih podataka koji se obrađuju, kao i sva potrebna
        objašnjenja u tom smislu; <br />
        Rok čuvanja podataka Korisnika od strane Adriaticum Qualitas d.o.o. traje doživotno ili do
        primitka zahtjeva za brisanje od strane pojedinca na koga se osobni podaci odnose, nakon čega se
        osobni podaci brišu. Adriaticum Qualitas d.o.o. čuva osobne podatke dulje od navedenog roka samo
        ako je to obvezujuće važećim propisima u RH ili nadnacionalnim zakonodavstvom.
    </p>

    <p>
        Osobni podaci koji više nisu potrebni se ili nepovratno anonimiziraju ili se anuliraju na
        siguran način.
    </p>

    <p>
        Ukoliko Korisnik ima prigovore na obradu njegovih podataka od strane Adriaticum Qualitas d.o.o.
        može uložiti svoj prigovor nadležnom državnom tijelu, sukladno Općoj uredbi o zaštiti osobnih
        podataka i Zakonu o provedbi Opće uredbe o zaštiti podataka.
    </p>

    
</div></div>
  )
}

export default opciUvjetiPolitikaPrivatnosti