import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import classes from "./fileUpload.module.css";

function PropertyForm() {
  const [state, setState] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate();

  const handleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let filenames = [];

    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const filename = crypto.randomUUID() + selectedFiles[i].name;
        filenames.push(filename);
        formData.append("images", selectedFiles[i], filename);
      }

      formData.append("images", JSON.stringify(filenames));

      try {
        await axios.post("/upload/images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.error("Server error:", error.response);
      }
    }

    try {
      const propertyFormData = {
        ...state,
        img: filenames,
      };

      await axios.post("/property", propertyFormData);

      navigate(`/`);
    } catch (error) {
      console.error("Server error:", error.response);
    }
  };

  return (
    <div className={classes.container}>
      <h1>Unesi nekretninu:</h1>
      <form onSubmit={handleSubmit}>
        <p>Naslov (lokacija nekretnine):</p>{" "}
        <input type="text" name="title" value={state?.title || ""} onChange={handleState} />
        <p>Prodaja ili najam:</p>
        <select value={state?.propertyType} name="type" onChange={handleState}>
          <option value="">Odaberi</option>
          <option value="prodaja">Prodaja</option>
          <option value="najam">Najam</option>
        </select>
        <p>Unesite tip nekretnine: </p>
        <select value={state?.propertySubType} name="subType" onChange={handleState}>
          <option value="">Odaberi</option>
          <option value="stan">Stan</option>
          <option value="kuca">Kuća</option>
          <option value="poslovni prostor">Poslovni prostor</option>
          <option value="zemljiste">Zemljište</option>
        </select>
        <p>Opis nekretnine:</p>
        <textarea
          value={state?.description}
          name="desc"
          onChange={handleState}
          rows={6} // You can adjust the number of rows as needed
          cols={50} // You can adjust the number of columns as needed
        ></textarea>
        <p>
          Unesi sliku:{" "}
          <input
            type="file"
            id="selectedFile"
            accept="image/*"
            onChange={(e) => setSelectedFiles(e.target.files)}
            multiple
          />
        </p>
        <p>Unesi cijenu: </p>
        <input value={state?.price || ""} name="price" type="number" onChange={handleState} />
        <p>Unesi kvadraturu: </p>{" "}
        <input type="number" value={state?.sqmeters || ""} name="sqmeters" onChange={handleState} />
        <p>Unesi broj soba: </p>
        <input type="number"  min="1" max="8" value={state?.numOfRooms || 1} name="numOfRooms" onChange={handleState} />
        <p>Unesi broj kupaonica: </p>{" "}
        <input type="number" min="1" max="8" value={state?.numOfBathrooms || 1} name="numOfBathrooms" onChange={handleState} />
        <p>Orijentacija: </p>
        <select value={state?.orientation} name="orientation" onChange={handleState}>
          <option value="">Odaberi</option>
          <option value="sjever">Sjever</option>
          <option value="jug">Jug</option>
          <option value="istok">Istok</option>
          <option value="zapad">Zapad</option>
        </select>
        <p>Unesi kat: </p>
        <select value={state?.floor} name="floor" onChange={handleState}>
          <option value="">Odaberi</option>
          <option value="suteren">Suteren</option>
          <option value="prizemlje">Prizemlje</option>
          <option value="1">1. kat</option>
          <option value="2">2. kat</option>
          <option value="3">3. kat</option>
          <option value="4">4. kat</option>
          <option value="5">5. kat</option>
          <option value="6">6. kat</option>
          <option value="7">7. kat</option>
          <option value="8">8. kat</option>
          <option value="9">9. kat</option>
          <option value="10+">10. kat +</option>
        </select>
        <p>Unesi tip vanjskog prostora:</p>
        <select value={state?.outerSpace} name="outerSpace" onChange={handleState}>
          <option value="">Odaberi</option>
          <option value="balkon">Balkon</option>
          <option value="loda">Lođa</option>
          <option value="terasa">Terasa</option>
          <option value="balkon i loda">Balkon i lođa</option>
          <option value="balkon i terasa">Balkon i terasa</option>
          <option value="loda i terasa">Lođa i terasa</option>
        </select>
        <h2>Objavi nekretninu: </h2> <input type="submit" />
      </form>
    </div>
  );
}

export default PropertyForm;
